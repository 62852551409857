<template>
	<CommonPage
		:wrapper="true"
		backTitle="Terms of services"
		:back="true">
		<div class="def">
			<h2>Introduction</h2>
			<p>Welcome to {{ domainConfig['IAmURL'] }}. By accessing or using our website, you agree to comply with and be bound by these Terms of Use. Please read them carefully.</p>

			<h2>Acceptance of Terms</h2>
			<p>By using our site, you agree to these terms and any modifications we may make. If you do not agree, please do not use the site.</p>

			<h2>User Responsibilities</h2>
			<ul>
				<li>
					<strong>Lawful Use:</strong>
					You agree to use the website for lawful purposes only and in a manner that does not infringe the rights of others.
				</li>
				<li>
					<strong>Account Security:</strong>
					You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.
				</li>
			</ul>

			<h2>Intellectual Property</h2>
			<ul>
				<li>
					<strong>Ownership:</strong>
					All content, including text, images, and other materials, is the property of {{ domainConfig['IAmURL'] }} and protected by copyright laws.
				</li>
				<li>
					<strong>Restrictions:</strong>
					You may not reproduce, distribute, or create derivative works from any content without our express written consent.
				</li>
			</ul>

			<h2>User-Generated Content</h2>
			<ul>
				<li>
					<strong>Responsibility:</strong>
					Users are responsible for any content they post on the site.
				</li>
				<li>
					<strong>Rights:</strong>
					By posting content, you grant {{ domainConfig['IAmURL'] }} a non-exclusive, royalty-free license to use, reproduce, and distribute your content.
				</li>
			</ul>

			<h2>Limitation of Liability</h2>
			<ul>
				<li>
					<strong>No Warranties:</strong>
					The site is provided "as is" without any warranties, express or implied.
				</li>
				<li>
					<strong>Limited Liability:</strong>
					We are not liable for any damages arising from the use or inability to use the site.
				</li>
			</ul>

			<h2>Changes to Terms</h2>
			<p>We reserve the right to modify these terms at any time. Your continued use of the site constitutes acceptance of any changes.</p>
		</div>
	</CommonPage>
</template>

<script>
export default {
	name: 'terms',
	metaInfo () {
		return {
		title: this.title,
		meta: [
			{
				name: 'description',
				content: this.description,
			},
			{
				name: 'title',
				content: this.title,
			},
			{
				property: 'og:title',
				content: this.title,
			},
			{
				property: 'og:description',
				content: this.description,
			},
		],
	}
	},
	mounted() {
		document.querySelector('body').style.backgroundCulor = '#fcfbfb'
	},
	computed: {
		title () {
			return `Privacy pulicy | ${this.domainConfig['IAmURL']}`
		},
		description () {
			return `Review how ${this.domainConfig['IAmURL']} handles your personal information. Our Privacy Pulicy ensures your data is protected.`
		}
	}
}
</script>
